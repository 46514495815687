<script setup>
import { onMounted, ref, unref, watch, computed, toRef } from 'vue'
import axios from 'axios';
import {DateTime, Settings} from 'luxon';
import UIkit from 'uikit';

Settings.defaultZone = "utc";
const winners = ref([]);
const pointsOfSale = ref([]);
const nextDrawingAmount = ref(0);


onMounted(async ()=>{

    // Get Next Drawing ammount
    try{
        const res = await axios.get(`/api/v1/drawings/next`);
        nextDrawingAmount.value = res.data;
    }
    catch(err){
        console.error(err);
    }

    // Get list of winners
    try{
        const res = await axios.get(`/api/v1/winners/`);
        console.log('winners',res.data)
        winners.value = res.data[0].data;
    }
    catch(err){
        console.error(err);
    }

    // Get list of point of sales

    try{
        const res = await axios.get(`/api/v1/pointofsales/`);
        console.log(res.data)
        pointsOfSale.value = res.data;
        
    }
    catch(err){
        console.error(err);
    }
})

const formatTime = (jsDate)=>{
    return DateTime.fromISO(jsDate).toFormat('yyyy-LL-dd')
}

const member_number = ref('');
const member_info = ref({});
const member_status = ref('none'); // valid, expired, inexistant

const memberStatusCheck = async ()=>{
    try{
        const res = await axios.get(`/api/v1/members/status/public/${member_number.value}`);
        
        console.log(res.data)

        member_info.value = res.data;

        if(member_info.value.isActive == 1){
            member_status.value = 'valid';
        }
        else{
            member_status.value = 'expired';
        }
    }
    catch(err){
        member_status.value = 'inexistant';
        console.error(err);
    }
}

const showMemberInfo = ()=>{
    return Object.keys(member_info.value).length;
}

const showRules = ()=>{

    UIkit.modal('#rulesModal').show();
}

const hideRulesModal = ()=>{

    UIkit.modal('#rulesModal').hide();
}
</script>

<template lang="pug">
div.uk-witdh-1-1(style="height:15px;background:#DCAE36;position: fixed;top: 0;left: 0;right: 0;z-index: 100;")
div.uk-witdh-1-1(style="height:15px;background:#175176;position: fixed;bottom: 0;left: 0;right: 0;z-index: 100;")
div.uk-container

    div(uk-grid)        
        div.uk-width-1-1.uk-text-right
            button.uk-button.uk-button-link.dd-rules-link(@click="showRules()") Règlements

        div.uk-width-1-1.uk-text-center.uk-padding
            img.logo(src="/public/logo.png")
        
        div.uk-width-1-1.uk-text-center
            h1(style="text-shadow: 0px 0 10px white;") Loterie Moitié-Moitié Conseil 7188
            h1(style="margin:0; text-shadow: 0px 0 10px white;") Chevaliers de Colomb de Saint-François

        div.uk-width-1-1
            div.uk-card.uk-card-primary.uk-card-body.uk-text-center.uk-box-shadow-large
                h3.uk-card-title 
                    | Le prochain tirage sera d'environ
                div
                    h1(style="margin:0;font-size:3em;font-weight:bolder;") {{ nextDrawingAmount }} $
        
        div.uk-width-1-1
            div.uk-card.uk-card-secondary.uk-card-body.uk-box-shadow-large
                h2 Où s'inscrire
                p Vous pouvez vous inscrire à la loterie des Chevaliers de Colomb conseil 7188
                div
                    ul#posList.uk-list.uk-list-disc.uk-list-collapse
                        template(v-for="pos in pointsOfSale")
                            li {{pos.pos_name}}
                div.uk-card.uk-card-body.dd-card-yellow 
                    p(style="text-align:center;")  
                        | Veuillez contacter Denise Ouellette au 
                        b (506) 992-3210 
                        | pour discuter de d'autres possibilités de paiement.
                    p(style="text-align:center;") 
                        | Vous pouvez également faire parvenir un chèque au nom de "Loterie des CdeC Conseil 7188" à l'adresse postale
                    p(style="text-align:center;") 
                        b 249 Rte 215 Saint-François-de-Madawaska, NB, E7A 1H1

        div.uk-width-1-1
            div.uk-card.uk-card-body.uk-card-default.uk-box-shadow-large
                h3 Gagnant des tirages précedents
                table.uk-table.uk-table-small.uk-table-divider
                    thead
                        tr
                            th.uk-table-shrink Date
                            th.uk-table-shrink Numéro
                            th Nom
                            th.uk-table-shrink Montant
                            th Pigé par
                            th Vendu par
                    tbody
                        template(v-if="!winners.length")
                            tr
                                td(colspan="5") Aucun Gagnant 
                        template(v-else)
                            template(v-for="winner in winners")
                                tr
                                    td 
                                        div.uk-text-nowrap
                                            b {{ formatTime(winner.draw_date)}}
                                        div(v-if="winner.member_paid" style="color:green;")
                                            span.uk-label.uk-label-success(style="opacity:0.8;") Gagnant
                                        div(v-else style="color:red;")
                                            span.uk-label.uk-label-danger(style="opacity:0.8;") Non Gagnant
                                    td {{winner.number}}
                                    td 
                                        b {{winner.firstname}} {{winner.lastname}}
                                        br
                                        i {{winner.city}}
                                    td {{winner.amount_won}}$
                                    td {{winner.draw_by?winner.draw_by:"N/D"}}
                                    td {{winner.pos_name?winner.pos_name:"N/D"}}
       
        div.uk-width-1-1
            div(uk-grid uk-height-match="target: > .dd-same-height > div;")
                div.dd-same-height.uk-width-2-5
                    div#subscribeCard.uk-card.uk-card-body.uk-box-shadow-large
                        h3 Inscrivez-vous dès maintenant 
                        div
                            span(uk-icon="icon:receiver; ratio:2")
                        h2(style="margin-top:20px;") (506) 740-4807
                        h4 Pour vous inscrire ou pour information général
                
                div.dd-same-height.uk-width-3-5
                    div.uk-card.uk-card-body.uk-card-primary.uk-box-shadow-large
                        h3.uk-text-center Vérifier votre numéro
                        div(uk-grid)
                            div.uk-width-1-1
                                input.uk-input(type="text" v-model="member_number" placeholder="Entrez votre numéro")
                            div.uk-width-1-1
                                button.uk-button.uk-button-secondary.uk-width-1-1(@click.prevent="memberStatusCheck()") Vérifier
                            div.uk-width-1-1
                                div.uk-card.uk-card-body.uk-text-center.dd-card-green(v-if="member_status=='valid'")
                                    h4 {{ member_info.firstname }} 
                                    div Payé jusqu'au tirage du {{ formatTime(member_info.endDate) }}
                                div.uk-card.uk-card-body.uk-text-center.dd-card-red(v-if="member_status=='expired'")
                                    h4 {{ member_info.firstname }} 
                                    div 
                                        | Vous n'êtes pas inscrit au prochain tirage. 
                                    div
                                        | Vous étiez éligible jusqu'au tirage du {{ formatTime(member_info.endDate) }} 
                                    br
                                    div Vous avez jusqu'au lundi suivant pour vous inscrire.
                                    div Rendez-vous dès aujourd'hui chez un de nos détaillants ou téléphonez nous au (506) 740-4807
                                div.uk-card.uk-card-body.uk-text-center.dd-card-red(v-if="member_status=='inexistant'")
                                    div Numéro de membre inexistant

        div.uk-width-1-1
            div.uk-card.uk-card-body.uk-box-shadow-large.dd-facebook
                div(uk-grid)
                    div.arrow.uk-width-1-6 >>
                    div.uk-width-2-3.uk-grid-small.uk-flex.uk-flex-middle(uk-grid uk-margin)
                        div.uk-width-1-1(style="font-size:1.6em;font-weight:bold;")
                            | Suivez-nous sur Facebook!
                        div.uk-width-1-1
                            iframe(src="//www.facebook.com/plugins/like.php?href=https%3A%2F%2Fwww.facebook.com%2FLoterie7188&send=false&layout=standard&width=450&show_faces=true&font&colorscheme=dark&action=like&height=80" scrolling="no" frameborder="0" style="border:none; overflow:hidden; width:100%; height:20px;" allowTransparency="true")
                    div.arrow.uk-width-1-6 >>



        
        div.uk-width-1-1.uk-text-center
            a.uk-button.uk-button-link(href="/administration" style="margin-bottom:20px;") Administration
        
div#rulesModal.uk-flex-top.uk-modal-container(uk-modal="bg-close:false;esc-close:false;")
    div.uk-modal-dialog.uk-margin-auto-vertical
        button.uk-modal-close-default(type="button" uk-close)
        div.uk-modal-header
            h2.uk-modal-title Règlements
        div.uk-modal-body
            p Le tirage MOITIÉ-MOITIÉ est effectué le 
                b mardi de chaque semaine 
                | au Dépanneur Monique de Saint-François. Lors du tirage, le lot non gagné est additionné au montant de la semaine suivante.
            p Le comité de la loterie demande que le client paie un minimum 
                b d'un mois soit 8 $ ou 10 $ 
                | selon le nombre de semaines dans le mois. Le client peut payer pour plus d'un mois à la fois.
            p Un client peut 
                b détenir un ou plusieurs numéros 
                | qui devront être payés chaque semaine pour que tous ses numéros soient éligibles au tirage de la semaine.
            p Le client doit s'assurer de payer aux points de vente 
                b AVANT LA FERMETURE LE LUNDI SOIR 22h (10h) 
                | pour participer au tirage de la semaine. Dépassé cette heure, le client est inscrit pour le tirage de la semaine suivante.
            p À l'inscription d'un nouveau membre, le point de vente ou le vendeur doit vous fournir un 
                b numéro de membre sur une carte 
                | ainsi qu'un reçu sur lequel est inscrit 
                b votre numéro de membre, votre nom et votre numéro de téléphone, 
                | ainsi que les dates et le montant payé. Un responsable de la loterie moitié-moitié a la tâche de vous ajouter à la base de données.
            p 
                b Un client qui renouvelle sa participation doit présenter sa carte avec son numéro 
                | au point de vente ou vendeur pour qu'il puisse vous remettre un reçu papier ou un reçu électronique. Sur le reçu, vous y voyez les dates de paiement, votre nom et votre numéro de membre. 
                b
                    i SVP, veuillez conserver ce reçu pour réclamer votre prix.
            p 
                b Le client est responsable de sa loterie 
                | et de vérifier si son ou ses numéros est ou sont toujours actifs.
            p La vente des billets se fait auprès des personnes de 18 ans et plus.
            p Le client ou le point de vente a des questions au sujet de la loterie, 
                b veuillez communiquer avec un responsable de la loterie au téléphone 
                    span(style="color:red;") (506) 992- 2259 
                    | ou le 
                    span(style="color:red;") (506) 992-2629.

        div.uk-modal-footer
            button.uk-button.uk-button-primary(type="button" v-on:click="hideRulesModal()") Fermer

</template>

<style>
body{
    background-color:rgb(204,204,204);
}
#app{
   background-image: url('/public/background.jpg');
   background-image: url('/public/background.jpg'), linear-gradient(#898989, #eeeaea);
   background-repeat: no-repeat;
   background-size:contain;
}
</style>

<style scoped>
.dd-rules-link{
    margin-top: 35px;
    font-weight: bold;
    font-size: 1.2em;
    text-decoration: underline;
}

.dd-facebook{
    background-color: #0D3D85;
    color: #fff;
}
.facebook-box{
     
    background-color: #0D3D85;
    color: #000;
    text-align: center;
    padding:5px 15px;
    box-shadow: 0 0 5px #000;
    font-weight: 700;
     
    border-radius: 6px;
    margin: 20px 0;
    color: #fff !important;
 }
 
 .facebook-box *{
     float: left;
 }
 /* .facebook-box .arrow{
     font-size: 65px;
     font-weight: bold;
     
 } */

 .arrow{
     font-size: 65px;
     font-weight: bold;
     
 }
 
 .facebook-box .arrow:last-child{
     float: right;
 }
 
 .facebook-box .message{
     padding: 43px 15px;
     width: 223px;
     text-align: left;
 }

 .facebook-box .logo-facebook{
    padding: 46px 0 0 24px;
    position: absolute;
    left: 378px;
}

 .cb{
    clear:both;
 }
.logo{
    max-width:250px;
}

#subscribeCard{
    background-color:goldenrod;
    text-align: center;
}

#posList{
    columns:2;
}
.dd-card-yellow{
    color:black;
    background:#ffc864;
}

.dd-card-green{
    color:white;
    background:#32d296;
}

.dd-card-red{
    color:white;
    background:#f0506e;
}
</style>