import { createApp } from 'vue'
import { createPinia } from 'pinia'

import 'uikit/dist/css/uikit.css'

import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';

import App from './Public.vue'
// import router from './router'

// import './assets/main.css'
UIkit.use(Icons);
const app = createApp(App)

app.use(createPinia())
// app.use(router)

app.mount('#app')
